import { ImageProps } from 'next/image';

import Image from 'components/display/Image';
import Button from 'components/interactive/Button';
import Page from 'components/layout/Page';
import routes from 'constants/routes';

import styles from './ErrorPage.module.scss';

type ErrorPageProps = {
  title: string;
  heading: string;
  description: string;
  image: ImageProps['src'];
};

const ErrorPage = (props: ErrorPageProps) => {
  const { title, heading, description, image } = props;

  return (
    <Page title={title}>
      <div className={styles.container}>
        <Image src={image} />
        <h1 className={styles.heading}>{heading}</h1>
        <p className={styles.description}>{description}</p>
        <Button className={styles.button} href={routes.home}>
          Return Home
        </Button>
      </div>
    </Page>
  );
};

export default ErrorPage;

import ErrorPage from 'components/layout/ErrorPage';

import error404 from './images/404.svg';

const Error404 = () => {
  return (
    <ErrorPage
      title='Page Not Found'
      heading='Oops!'
      description="We can't seem to find the page that you're looking for."
      image={error404}
    />
  );
};

export default Error404;
